import React from "react"
import background from "../Figures/geweestengoedgekeurd.png"

export default function Contact_geg() {
  return (
    <div style={{ margin: `3rem auto`, maxWidth: "60%", padding: `0 1rem` }}>
      <figure>
        <img width="100%"  src={background} style={{}} alt="" />
        </figure>
      <h1>Laat weten welke toplocatie jij hebt bezocht!</h1>
      <p>Vergeet er zeker niet de link tot te voegen naar de webpagina van de accomodatie en te laten weten waarom jij de locatie zou aanraden aan anderen!</p>
      <form name="contact_geg" method="POST" data-netlify="true" netlify-honeypot="bot-field">
      <input type="hidden" name="form-name" value="contact" />
      <input type="hidden" name="bot-field" />
  <p>     <label>Uw Naam: </label> <br /> <input style={{width:"100%"}} type="text" name="name" /> </p>
  <p>
    <label>Uw Email: <br /><input style={{width:"100%"}} type="email" name="email" /></label>
  </p>
  <p>
    <label>Bericht: <br /><textarea style={{width:"100%"}} name="message"></textarea></label>
  </p>
  <p>
    <button type="submit">Verstuur</button>
  </p>
</form>
</div>
  )
}